import React, { FC } from 'react';
import styled from 'styled-components';

import Heading, { HeadingScope, HeadingVariant } from 'src/components/Heading';
import IconLabel, { IconSize } from 'src/components/IconLabel';
import { PhoneIcon, LocationIcon, EmailIcon, FaxIcon } from 'src/assets/icons';
import { mediaQuery } from 'src/theme/breakpoints';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mediaQuery('md')} {
    flex-direction: row;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:first-child > :not(:first-child) {
    margin-top: 12px;
  }

  ${mediaQuery('md')} {
    margin-right: 20px;
    margin-top: 0;
  }
`;

interface ContactsProps {
  phone: string;
  fax: string;
  email: string;
  address: string;
  companyInfo: React.ReactNode;
  bankInfo: React.ReactNode;
}

const Contacts: FC<ContactsProps> = ({
  phone,
  fax,
  email,
  address,
  companyInfo,
  bankInfo,
}) => (
  <HeadingScope>
    <Wrapper>
      <Col>
        <Heading variant={HeadingVariant.DELTA}>Kontaktai</Heading>
        <a href={`tel:${phone}`}>
          <IconLabel
            icon={<PhoneIcon />}
            label={phone}
            size={IconSize.MEDIUM}
            isDark
          />
        </a>
        <IconLabel
          icon={<FaxIcon />}
          label={fax}
          size={IconSize.MEDIUM}
          isDark
        />
        <a href={`mailto:${email}`}>
          <IconLabel
            icon={<EmailIcon />}
            label={email}
            size={IconSize.MEDIUM}
            isDark
          />
        </a>
        <IconLabel
          icon={<LocationIcon />}
          label={address}
          size={IconSize.MEDIUM}
          isDark
        />
      </Col>
      <Col>
        <Heading variant={HeadingVariant.DELTA}>Rekvizitai</Heading>
        <div dangerouslySetInnerHTML={{ __html: companyInfo }} />
      </Col>
      <Col>
        <Heading variant={HeadingVariant.DELTA}>
          Banko informacija mokėjimams:
        </Heading>
        <div dangerouslySetInnerHTML={{ __html: bankInfo }} />
      </Col>
    </Wrapper>
  </HeadingScope>
);

export default Contacts;

import { graphql } from 'gatsby';
import React, { FC } from 'react';

import Contacts from 'src/components/Contacts';
import SEO from 'src/components/Seo/Seo';
import Banner from 'src/components/Banner';
import { HeadingScope } from 'src/components/Heading';
import Main from 'src/components/Main';

interface ContactsPageProps {
  data: any; //TODO
}

const ContactsPage: FC<ContactsPageProps> = ({ data }) => {
  const {
    wpPage: { contactsFields: acfFields, seo },
  } = data;

  return (
    <HeadingScope>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={seo.opengraphImage?.localFile}
      />

      <Banner
        image={acfFields.headerImage.localFile.childImageSharp.gatsbyImageData}
        title={acfFields.title}
        subtitle={acfFields.subtitle}
      />
      <Main>
        <Contacts
          phone={acfFields.phone}
          fax={acfFields.fax}
          email={acfFields.email}
          address={acfFields.address}
          companyInfo={acfFields.companyInfo}
          bankInfo={acfFields.bankInfo}
        />
      </Main>
    </HeadingScope>
  );
};

export const query = graphql`
  query Contacts {
    wpPage(slug: { eq: "kontaktai" }) {
      contactsFields {
        title
        subtitle
        headerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        phone
        fax
        email
        address
        companyInfo
        bankInfo
      }
      ...YoastSeoPage
    }
  }
`;

export default ContactsPage;
